import { Link } from 'gatsby'
import React from 'react'
import { HOME } from '../layouts'

// local constants

const LOGO = <img alt="" className="icon__image" height="50" src="/logo.png" width="50" />

// exported components

export const Company = ({ path }) => {
  if (path === HOME) {
    return (
      <h1 className="name">
        <span className="name--logic">stayzen.jp</span>
      </h1>
    )
  }

  return (
    <Link className="name name--interior" to="/">
      <span className="name--logic">stayzen.jp</span>
    </Link>
  )
}

export const Logo = ({ path }) => {
  if (path === HOME) {
    return <span className="icon icon__logo icon__logo--current">{LOGO}</span>
  }

  return (
    <Link className="icon icon__logo" to={HOME}>
      {LOGO}
    </Link>
  )
}
